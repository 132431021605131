<template>
  <component :is="computedTag" class="list-group" :class="computedClasses">
    <slot />
  </component>
</template>

<script setup lang="ts">
import {computed, provide, toRef} from 'vue'
import {listGroupInjectionKey} from '../../utils'
import type {Booleanish, Breakpoint} from '../../types'
import {useBooleanish} from '../../composables'

interface BListGroupProps {
  flush?: Booleanish
  horizontal?: boolean | Breakpoint
  numbered?: Booleanish
  tag?: string
}

const props = withDefaults(defineProps<BListGroupProps>(), {
  flush: false,
  horizontal: false,
  numbered: false,
  tag: 'div',
})

const flushBoolean = useBooleanish(toRef(props, 'flush'))
const numberedBoolean = useBooleanish(toRef(props, 'numbered'))

const computedClasses = computed(() => {
  const horizontal = flushBoolean.value ? false : props.horizontal
  return {
    'list-group-flush': flushBoolean.value,
    'list-group-horizontal': horizontal === true,
    [`list-group-horizontal-${horizontal}`]: typeof horizontal === 'string',
    'list-group-numbered': numberedBoolean.value,
  }
})

const computedTag = computed<string>(() => (numberedBoolean.value === true ? 'ol' : props.tag))

provide(listGroupInjectionKey, {
  numbered: numberedBoolean,
})
</script>
